import axios from 'axios'
import React from 'react'
import { useQuery } from 'react-query'
import { useAuth0 } from '../../context/react-auth0-spa'
import { formatStationsToDisplayOnDropdownMenu } from '../helpers'
import { useLocalStorage } from './useLocalStorage'

export default function useStations() {
  const { user } = useAuth0()
  const [station, setStation] = useLocalStorage('newa_project_station', null)

  const { isLoading, isError, data } = useQuery(
    `newaStations`,
    () =>
      axios
        .get(`https://newa.rcc-acis.workers.dev/v0/stations`)
        .then((res) => res.data),
    {
      refetchOnWindowFocus: false,
      // enabled: station !== null,
      refetchOnMount: false,
      cacheTime: 3600000 * 6, // data remains in memory for 6 hour
    },
  )

  let list = []
  if (!isLoading && !isError && data) {
    list = formatStationsToDisplayOnDropdownMenu(data)
  }

  const [favoriteStations, setFavoriteStations] = React.useState([])
  const [favoriteStationsUnformatted, setFavoriteStationsUnformatted] =
    React.useState([])

  React.useEffect(() => {
    if (!user) return

    if (Object.keys(user).includes('favoriteStations')) {
      setFavoriteStationsUnformatted(user.favoriteStations)
      const ids = user.favoriteStations.map((d) => d.id)
      const favoriteStations = list.map((state) => {
        let p = { ...state }
        p.options = state.options.filter((station) => ids.includes(station.id))
        return p
      })
      setFavoriteStations(favoriteStations)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, setFavoriteStations])

  let features = []
  let geoJSON = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: [-77.031, 42.8767] },
      },
    ],
  }
  if (data && !isError) {
    features = data
      .filter((stn) => stn.activeStatus === true)
      .map((stn) => ({
        type: 'Feature',
        properties: { ...stn },
        geometry: {
          type: 'Point',
          coordinates: [stn.lon, stn.lat],
        },
      }))
    geoJSON.features = features
  }

  return {
    isLoading,
    isError,
    stationList: list,
    favoriteStations,
    favoriteStationsUnformatted,
    station,
    setStation,
    geoJSON,
  }
}
