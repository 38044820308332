import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import React from "react"
import useStations from "../utils/hooks/useStations"

const MaintenanceGuidePage = () => {
  const { station } = useStations()
  return (
    <Layout station={station}>
      <Seo title="Weather Station Maintenance Guide" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <h1>Weather Station Maintenance Guide</h1>

            <p className="intro">
              Keep your weather station at optimum performance to ensure precise
              and accurate NEWA tool results.
            </p>

            <p>
              The quality of the weather data collected and delivered via NEWA
              is highly valued for crop production and IPM forecast
              applications. Maintain your weather station in good working
              condition according to the manufacturer’s recommendations for
              optimum data integrity. Below are tips for relative humidity and
              temperature sensors, precipitation (rain) gauges, leaf wetness
              sensors, wind speed anemometers, wind direction weather vanes, and
              solar radiation sensors. For details on weather variables and data
              quality control, visit{" "}
              <Link to="/about-weather-stations">About Weather Stations</Link> .
              If putting up a new weather station, visit{" "}
              <Link to="/placement-guide">Placement Guide</Link>.
            </p>

            <p>
              Routinely inspect your weather station to make sure all sensors
              are clean, free of debris and in good working condition.
              Periodically clean the solar panels that power the battery with an
              alcohol wipe, but if none are available use a soft, clean, damp
              cloth especially after spraying near the weather station.
            </p>

            <p>
              At least once per year, inspect cables and make certain mounting
              brackets, poles, posts, etc. are stable, vertical and sound and
              the instrument is securely fastened. Jostling during strong winds
              can affect radio transmission of data and tipping bucket rain
              gauges.
            </p>

            <p>
              Sometimes, spiders or other insects will make nests inside of the
              support pole and radiation shield. To prevent this, cover the
              holes with duct tape or silicone sealer. DO NOT seal the Temp/RH
              shield.
            </p>

            <p>
              Protect weather instruments, sensors and cables from pruning,
              spraying and harvesting activities that may damage the sensors,
              cut cables, or cause weather stations to lean. It is a good idea
              to get the serial number off of the part you are replacing, before
              contacting your vendor for a replacement, to ensure you receive
              the correct version of the sensor.
            </p>

            <hr></hr>
            <h2>Calibration</h2>

            <p>
              Ask your weather station vendor about sensor calibration
              requirements. It is best to calibrate weather stations about every
              two years. Contact your weather station vendor for instructions on
              sending the station in for calibration. Plan a good time to take
              out the station for maintenance, usually in late fall, winter, or
              early spring.
            </p>

            <h2>Contact your vendor</h2>

            <div className="pl-4 mb-10 border-l-2 lg:pl-6">
              <h2>
                <a
                  href="https://www.onsetcomp.com/corporate/partners/newa"
                  target="_blank"
                  rel="noopener noreferrer"
                >Onset Data Loggers
                </a>
              </h2>

              <p>
                Matt Sharp, Strategic Sales Representative<br></br>
                Environmental & Agricultural Monitoring<br></br>
                508.473.3126<br></br>
                <a href="mailto:matt_sharp@onsetcomp.com">
                  matt_sharp@onsetcomp.com
                </a>
              </p>
            </div>

            <div className="pl-4 mb-10 border-l-2 lg:pl-6">
              <h2>
                <a
                  href="https://kestrelmet.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >KestrelMet
                </a>
              </h2>

              <p>
                Brad Luscombe, Manager<br></br>
                Support, Sales, and Service<br></br>
                207.801.4043<br></br>
                <a href="mailto:bluscombe@rainwise.com">bluscombe@rainwise.com</a>
              </p>
            </div>

            <hr></hr>
            <h2>Relative humidity (RH) and temperature sensors</h2>

            <p>
              These sensors are delicate instruments that should be periodically
              checked, according to the manufacturer's recommendations, to make
              certain they are accurate. Compare the readings of the weather
              station sensors with a calibrated thermometer or with another
              nearby weather station. During dewy mornings or rainy weather, low
              RH readings indicate a sensor malfunction. Contact the station
              manufacturer to replace bad or broken sensors.
            </p>

            <h2>Precipitation (rain) gauges</h2>

            <p>
              Leaves, moss, algae, pollen, and debris will cause clogging of the
              tipping bucket screen. The tipping bucket should be cleaned
              routinely (at least 1-2 times per year). The surrounding
              environment will affect how often it should be cleaned. For
              example, where weather stations are placed in an open area near an
              orchard, vineyard or woods, make sure that tipping bucket rain
              gauges are not subject to filling with leaf litter from adjacent
              areas.
            </p>
            <p>
              Start by turning off the weather station. Loosen the 4 screws
              holding the bucket to the base of the rain gauge and twist
              counter-clockwise to remove it. Straighten the cotter pin legs
              holding the screen, pull the cotter pin out, remove the screen,
              and clear the drain hole. You can clean the collector and tipping
              bucket with warm soapy water if necessary. Make sure you rinse it
              well afterwards. When putting it back together, if the cotter pin
              legs hang down inside the bucket collector, they may interfere
              with the tipper mechanism. The cotter pin legs MUST be bent up so
              they don’t interfere with the tipper. Remember to turn the weather
              station back on after cleaning.
            </p>

            <p>
              Wasps may build nests in tipping bucket rain gauges. A small piece
              of Vapona® strip may be used as a deterrent.
            </p>

            <p>
              If improperly mounted, the weather station may be jostled during
              strong winds and this may cause the tipping bucket arm to tip and
              record a small amount of precipitation during dry weather.
            </p>

            <p>
              If the rain gauge continues to malfunction after
              cleaning, it is possible the reed switch is bad. In this
              situation, contact your weather station vendor or refer to the{" "}
              
              Read this NEWA blog,{" "}
              <a
                href="https://blogs.cornell.edu/yourenewa/2015/06/18/accuracy-of-rain-gauge-measurements/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Accuracy of Rain Gauge Measurements
              </a>
              , for more information.
            </p>

            <h2>Leaf wetness sensors</h2>

            <p>
              NEWA suggests that you place plastic grid type
              leaf wetness sensors facing north and angled 45 degrees from a
              horizontal orientation. If they are attached close to the weather
              station, this protects the sensor and cables from pruning,
              spraying and harvesting activities. Periodically check the plastic
              grid for cracks that would expose the metal wire grid. Contact the
              station manufacturer to replace any broken or cracked leaf wetness
              sensors.
            </p>

            <h2>Wind speed (anemometer)</h2>

            <p>
              The anemometer should spin freely and be free of debris of any
              kind. Periodically inspect the anemometer in strong wind
              conditions and calm conditions and check the data being recorded
              to make certain it is working correctly. Contact your station
              manufacturer for technical support or replacement.
            </p>

            <h2>Wind direction (wind vane)</h2>

            <p>
              The wind vane should be set to zero on due North in order for the
              readings to be accurate. Any time the weather station is moved or
              bumped, check the orientation of the wind vane relative to North.
              The wind vane should move freely and be free of debris of any
              kind.
            </p>

            <h2>Solar radiation</h2>

            <p>
              The solar radiation sensor must be kept clean to ensure accurate
              readings. Clean the glass diffuser with a damp cloth. Replace the
              diffuser when it yellows (usually after several years of service).
              It is essential to have good solar radiation readings in order to
              run the apple carbohydrate thinning model. If your station does
              not appear in the selection list of stations for that model, the
              solar radiation data is out-of-range. Should this become an issue,
              contact the station manufacturer about sensor calibration or
              replacement.
            </p>

            <hr></hr>

            <footer className="pt-6 mt-12 text-base border-t border-gray-200">
              <p>Page updated 4 January 2022 by D. Olmstead</p>
            </footer>
          </div>
          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
          <h2>Get a Weather Station</h2>
            <p>
              NEWA is compatible with special configurations of {' '}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset 
              </Link>{' '}
              and <Link to="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">KestrelMet</Link> weather instruments. See what your options are.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Buy a Weather Stations"
                to="/buy-a-weather-station"
              >
                Buy a Weather Station
              </Link>
            </p>

            <hr></hr>

            <h2>Have a question?</h2>
            <p>
              Reach out to our support center and ask questions to get support for NEWA tools, resources, and weather
              stations.
            </p>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Contact the NEWA Help Desk
              </a>
            </p>

            <hr></hr>

            <h2>Become a Partner</h2>
            <p>
              NEWA is a partnership of land grant universities and grower
              associations. If you live in a partner state, you can{" "}
              <Link to="/buy-a-weather-station">buy a weather station</Link> for
              your farm and connect to NEWA.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Learn More About Becoming a Partner"
                to="/become-partner"
              >
                Learn More
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default MaintenanceGuidePage
